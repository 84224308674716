.nav {


	&.nav--primary {

		.menu {
			display: flex;
			align-items: center;
			font-size: 20px;
			line-height: 24px;

			@include r($sm-breakpoint) {
				font-size: 16px;
				line-height: 24px;
			}

			.menu__item {
				margin: 0 32px;

				@include r($sm-breakpoint) {
					margin: 0 20px;
				}

				&.is-active a {
					color: #fff;
					pointer-events: none;

					@include r($sm-breakpoint) {
					    pointer-events: all;
					}
				}

				&.is-parent {

					@include rmin($sm-breakpoint) {
						&:hover {

							.dropdown {
								display: block;
								animation: transformCatalog .35s forwards ease-in-out;
							}

							> a {
								color: #fff;

								&:before {
									animation: transformCatalog .35s forwards ease-in-out;
								}

								&:after {
									width: 100%;
								}
							}
						}

						> a {
							position: relative;

							&:before {
								content: '';
								margin-top: 13px;
								width: 0;
								height: 0;
								border-style: solid;
								border-width: 0 10px 10px 10px;
								border-color: transparent transparent #454545 transparent;
								position: absolute;
								top: 100%;
								left: 50%;
								transform: translateX(-50%);
								opacity: 0;
							}
						}
					}
				}


				> a {
					position: relative;
					padding-bottom: 3px;

					&:after {
						content:'';
						position: absolute;
						top: 100%;
						left: 50%;
						transform: translateX(-50%);
						width: 0;
						background-color: #fff;
						height: 1px;
						border-radius: 20px;
						transition: .3s all;
					}

					&:hover {
						color: #fff;

						&:after {
							width: 100%;
						}
					}
				}
			}
		}

		.dropdown {
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);

			@include r($sm-breakpoint) {
				position: fixed;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				transform: none;
			}
		}
	}

	a {
		color: rgba(#fff, .5);

		&:hover {
			color: #fff;
		}

		@include r($sm-breakpoint) {
			white-space: nowrap;
		}
	}
}

[data-src="#catalogMenu"] {
	pointer-events: none;

	@include r($sm-breakpoint) {
	    pointer-events: all;
	}
}


@keyframes transformCatalog {
	from {
		transform: translateY(30px) translateX(-50%);
		opacity: 0;
		visibility: hidden;
	}

	to {
		transform: translateY(0px) translateX(-50%);
		opacity: 1;
		visibility: visible;
	}
}
