.section--previewModels {
	padding: 100px 0 160px;
	color: #000;
	position: relative;

	@include r($sm-breakpoint) {
		padding: 80px 0 120px;
	}

	@include r($xs-breakpoint) {
		padding: 20px 0 30px;
	}
}

.previewModels {
	display: flex;
	flex-flow: wrap;
	justify-content: space-between;
	margin: 0 -100px;

	@include r($sm-breakpoint) {
		margin: 0 -30px;
	}

	@include r($xs-breakpoint) {
		margin: 0 -38px;
	}

	.previewModels__item {
		width: 50%;
		padding: 0 100px;

		@include r($sm-breakpoint) {
			padding: 0 30px;
		}

		@include r($xs-breakpoint) {
			width: 100%;
		}

		&:not(:first-child) {
			margin-top: 200px;

			@include r($sm-breakpoint) {
				margin-top: 120px;
			}

			@include r($xs-breakpoint) {
				margin-top: 21px;
			}

		}

		&:nth-child(2) {
			margin-top: 300px;

			@include r($sm-breakpoint) {
				margin-top: 282px;
			}

			@include r($xs-breakpoint) {
				margin-top: 21px;
			}
		}
	}
}

.card {
	position: relative;
}

.card--model {
	background-color: #fff;
	border-radius: 8px;
	padding: 40px 45px 55px;

	@include r($sm-breakpoint) {
		padding: 30px 30px 35px;
	}

	@include r($xs-breakpoint) {
		padding: 20px 30px 43px;
	}

	.card__photo {
		margin-bottom: 40px;

		@include r($sm-breakpoint) {
			margin-bottom: 30px;
		}

		@include r($xs-breakpoint) {
			margin-bottom: 20px;
		}

		img {
			width: 100%;
		}
	}

	.card__title {
		font-weight: 500;
		font-size: 22px;
		line-height: 30px;
		text-align: center;
		margin-bottom: 20px;

		@include r($sm-breakpoint) {
			padding: 0 15px;
		}

		@include r($xs-breakpoint) {
			font-size: 20px;
			line-height: 26px;
			margin-bottom: 15px;
		}
	}

	.card__subtitle {
		font-weight: 300;
		padding: 5px 10px;

		@include r($xs-breakpoint) {
			padding: 0;
		}
	}
}
.scrollColumn {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 4px;
	background-color: rgba(#000, .1);

	@include r($xs-breakpoint) {
		display: none;
	}


	.scrollColumn__fill {
		display: block;
		width: 100%;
		height: 0;
		transition: .3s all ease-out;
		border-bottom-left-radius: 100px;
		border-bottom-right-radius: 100px;
	}
}
