.header {
	padding: 36px 0;
	position: relative;
	z-index: 111;

	@include r($sm-breakpoint) {
		padding: 25px 0;
	}

	@include r($xs-breakpoint) {
		padding: 20px 0;
	}

	.header__inner {
		display: flex;
		align-items: center;
	}


	.header__burger {
		display: none;

		@include r($xs-breakpoint) {
			display: block;
		}
	}

	.header__left {
		@include r($xs-breakpoint) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.logo img{

		@include r($sm-breakpoint) {
		    max-width: 84px;
		}

		@include r($xs-breakpoint) {
		    max-width: 53px;
		}
	}

	.header__center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		@include r($xs-breakpoint) {
			position: relative;
			left: 0;
			top: 0;
			transform: none;
			margin-left: auto;

			.menu__item {
				margin: 0 !important;
			}

			.menu__item:not(:first-child) {
				display: none;
			}
		}
	}

	.header__right {
		margin-left: auto;

		@include r($xs-breakpoint) {
			display: none;
		}
	}
}


