textarea,
input {
	border-radius: 0;
	background-color: #fff;
	font-family: inherit;
	color: #000;
	padding-left: 20px;
	box-shadow: none;

	&::placeholder {
		color: #A5A5A5;
	}
}

textarea {
	resize: none;
	padding-top: 10px;
}


.textarea,
.input {

}

.input {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	width: 100%;
	height: 50px;

	&.input--sm {

	}

	&.input--xs {

	}
}

.textarea {
	min-height: 50px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	width: 100%;
}

.label {
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 9px;
	display: block;
	font-family: inherit;
	font-weight: 300;
}
