.contentCard__nav {
	background: #F3F3F3;

	&.is-fixed {
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
		width: 100%;
		z-index: 50;
	}

	@include r($xs-breakpoint) {
		display: none;
	}

	.menu {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 -32px;

		@include r($sm-breakpoint) {
			margin: 0 -24px;
		}

		.menu__item {
			margin: 0 32px;

			@include r($sm-breakpoint) {
				margin: 0 24px;
			}

			&:hover,
			&.is-active a {
				color: #000;
			}

			&.is-active a {
				pointer-events: none;
			}

			a {
				color: rgba(#000, .5);
				padding: 30px 0 33px;
				display: block;
				position: relative;

				&:hover {
					color: #000;

					&:before {
						width: 100%;
					}
				}

				&:before {
					content: '';
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					width: 0;
					transition: .3s all;
					height: 1px;
					border-radius: 100px;
					background-color: #000;
					margin-bottom: 33px;
				}
			}
		}
	}
}

.contentCard {

	&__item[style] {
		color: #000;
	}

	&__item {

		.textBlock {
			max-width: 700px;
			margin: 0 auto;
			font-weight: 300;
			padding: 53px 0 62px;

			@include r($sm-breakpoint) {
				max-width: 520px;
				padding: 57px 0 61px;
			}

			@include r($xs-breakpoint) {
				padding: 32px 0 43px;
			}

			p:first-child {
				margin-top: 0;
			}
		}

		.listDefaultContainer {
			padding-bottom: 52px;

			@include r($sm-breakpoint) {
				padding-bottom: 52px;
			}

			@include r($xs-breakpoint) {
				padding-bottom: 42px;
			}
		}
	}
}

.contentCard__title {
	font-size: 26px;
	line-height: 26px;
	text-align: center;
	margin-bottom: 47px;

	@include r($sm-breakpoint) {
		margin-bottom: 48px;
	}

	@include r($xs-breakpoint) {
		font-size: 22px;
		line-height: 30px;
		margin-bottom: 34px;
	}

	&:first-child {
		padding-top: 45px;

		@include r($sm-breakpoint) {
			padding-top: 55px;
		}

		@include r($xs-breakpoint) {
			padding-top: 27px;
		}
	}

	+ .advantagesList {
		margin-top: 59px;

		@include r($sm-breakpoint) {
			margin-top: 50px;
		}

		@include r($xs-breakpoint) {
			margin-top: 35px;
		}
	}
}

.listDefault {
	display: flex;
	flex-wrap: wrap;

	&__column {
		width: 33.33%;

		@include r($sm-breakpoint) {
			width: 50%;
		}

		@include r($xs-breakpoint) {
			width: 100%;
		}

		&:not(:nth-child(-n+2)) {

			@include r($sm-breakpoint) {
				margin-top: 39px;
				display: none;
			}

			@include r($xs-breakpoint) {
				margin-top: 0;
			}
		}

		&:not(:first-child) {

			@include r($xs-breakpoint) {
				margin-top: 29px;
			}
		}
	}

	&__item {
		text-align: center;
		font-weight: 300;

		&:not(:nth-child(-n+3)) {
			display: none;
		}

		&:not(:first-child) {
			margin-top: 29px;

			@include r($sm-breakpoint) {
				margin-top: 39px;
			}

			@include r($xs-breakpoint) {
				margin-top: 29px;
			}
		}
	}

	&__title {
		color: rgba(0, 0, 0, 0.5);
		margin-bottom: 5px;
	}

	&__subtitle {
		padding: 0 40px;
	}
}

.contentCardSlider {
	padding: 93px 0 120px;

	@include r($sm-breakpoint) {
		padding: 73px 0 60px;
	}

	@include r($xs-breakpoint) {
		padding: 52px 0 42px;
	}

	&__item {
		position: relative;

		.swiper-button {
			margin-top: -17px;
			background-image: none;

			@include r($sm-breakpoint) {
				display: block;
			}

			@include r($xs-breakpoint) {
				display: none;
			}

			&.swiper-button-next {
				right: -87px;

				@include r($sm-breakpoint) {
					right: -10px;
				}
			}

			&.swiper-button-prev {
				left: -87px;


				@include r($sm-breakpoint) {
					left: -10px;
				}
			}
		}
	}

	.contentCardSlider__main {

		@include r($xs-breakpoint) {
			padding-bottom: 44px;
		}

		.swiper-pagination {
			display: none;
			bottom: 0;

			@include r($xs-breakpoint) {
				display: block;
			}

			.swiper-pagination-bullet {
				width: 6px;
				height: 6px;
				margin: 0 6px;
				opacity: .2;
				background-color: #000;


				&.swiper-pagination-bullet-active {
					opacity: 1;
				}
			}
		}
	}

	.contentCardSlider__thumbs {
		margin-top: 20px;
		overflow: visible;

		@include r($xs-breakpoint) {
			display: none;
		}

		.swiper-slide {
			opacity: .2;
			transition: .3s all;

			@include r($sm-breakpoint) {
				opacity: 1;
			}

			&.swiper-slide-next,
			&.swiper-slide-next + .swiper-slide {
				opacity: 1;
			}

			&.is-active {
				opacity: 1 !important;

				&:before {
					opacity: .7;
				}

			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: #998270;
				opacity: 0;
				transition: .3s all;
			}

		}
	}
}

.textBlock {

	&.is-active {

		.textBlock__more {
			display: none;
		}

		.textBlock__inner {
			max-height: 2000px;


			p:not(:nth-child(-n+1)) {

				@include r($sm-breakpoint) {
					display: block;
				}
			}

			p:not(:nth-child(-n+2)) {
				display: block;
			}

			&:before {
				content: none;
			}
		}
	}

	.textBlock__inner {
		position: relative;
		transition: .3s all;

		@include r($xs-breakpoint) {
			max-height: 224px;
			overflow: hidden;
		}

		&:before {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 32.29%, rgba(255, 255, 255, 0) 100%);
			height: 62px;

			@include r($xs-breakpoint) {
				content: '';
			}
		}


		p:not(:nth-child(-n+1)) {

			@include r($sm-breakpoint) {
				display: none;
			}
		}

		p:not(:nth-child(-n+2)) {
			display: none;
		}
	}


	.textBlock__moreLink {
		display: block;
		color: rgba(#000, .5);
		margin-top: 24px;

		&:hover {
			color: #000;
		}

		@include r($xs-breakpoint) {
			text-align: center;
		}

		svg {
			margin-left: 5px;
			vertical-align: middle;
			display: inline-block;
		}

	}
}


.listDefaultToggle {
	margin-top: 44px;

	@include r($sm-breakpoint) {
		margin-top: 58px;
	}

	@include r($xs-breakpoint) {
		margin-top: 44px;
	}

	a {
		display: block;
		text-align: center;
		color: rgba(#000, .5);
		font-weight: 300;

		svg {
			display: inline-block;
			vertical-align: middle;
			margin-left: 5px;
		}
	}
}


.listDefaultContainer.is-active {

	.listDefault__item {
		display: block !important;
	}

	.listDefaultToggle {
		display: none;
	}
}
