button,
.btn {
	position: relative;
	display: inline-block;
	border-radius: 0;
	border: 0;
	transition: .3s all;
	background-color: transparent;
	text-align: center;
	padding: 0;
	cursor: pointer;
}

.btn--primary {
	background: #000000;
	border-radius: 112px;
	color: #fff;
	font-size: 24px;
	line-height: 55px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	font-family: $font-second;
	padding: 0 80px;
	height: 50px;

	&:hover {
		color: #fff;
		background-color: $primary-color;
	}
}

.btn--secondary {
	background: #fff;
	border-radius: 112px;
	color: #000;
	font-size: 24px;
	line-height: 55px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	font-family: $font-second;
	padding: 0 50px;
	height: 50px;

	&:hover {
		color: #fff;
		background-color: $primary-color;
	}
}

.btn--uppercase {
	text-transform: uppercase;
}

.btn--burger {

	.bar {
		background: #A79A81;
		border-radius: 2px;
		height: 2px;
		display: block;

		&:not(:first-child) {
			margin-top: 7px;
		}

		&:first-child {
			width: 36px;
		}

		&:nth-child(3),
		&:nth-child(2) {
			width: 22px;
		}
	}
}

[data-fancybox-close] {
	display: block;

	> * {
		pointer-events: none;
	}
}

.scrollUp {
	position: fixed;
	bottom: 16px;
	right: 16px;
	transform: translate(0, 100px);
	display: none;

	@include r($xs-breakpoint) {
		display: block;
	}

	&.is-active {
		transform: translate(0, 0);
	}
}
