.section--models {
	padding: 18px 0 35px;

	@include r($sm-breakpoint) {
		padding: 20px 0;
	}

	@include r($xs-breakpoint) {
		padding: 2px 0;
	}
}

.models {
	background-color: #fff;
	min-height: 520px;
	display: flex;
	flex-flow: wrap;

	@include r($xs-breakpoint) {
		min-height: auto;
	}

	a {
		color: rgba(#000, .5);

		&:hover {
			color: #000;
		}
	}

	.models__left {
		width: percentage(540/1060);
		padding: 76px 0 76px 90px;

		@include r($sm-breakpoint) {
			padding: 66px 0;
			text-align: center;
			width: 100%;
		}

		@include r($xs-breakpoint) {
			padding: 20px 0 0;
		}
	}

	.models__right {
		width: percentage(520/1060);
		margin-left: auto;

		@include r($sm-breakpoint) {
			width: 100%;
		}

		@include r($xs-breakpoint) {
			margin-top: 27px;
		}

		.models__picture,
		.lazy-load-wrap {
			height: 100%;

			@include r($xs-breakpoint) {
				height: auto;
			}

		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;

			@include r($xs-breakpoint) {
				height: auto;
			}
		}
	}

	&__title {
		font-family: $font-second;
		font-size: 60px;
		line-height: 60px;
		letter-spacing: 0.02em;
		color: #000;

		@include r($xs-breakpoint) {
			font-size: 40px;
			line-height: 46px;
			text-align: center;
			letter-spacing: 0.02em;
		}
	}

	.modelsList {
		margin-top: 58px;
		font-weight: 300;
		display: flex;
		flex-flow: wrap;
		max-width: 360px;

		@include r($sm-breakpoint) {
		    max-width: 520px;
			margin: 47px auto 9px;
		}

		@include r($xs-breakpoint) {
			margin: 19px 0 0;
		}

		.modelsList__item {
			width: 50%;

			&:not(:nth-child(-n+2)) {
				margin-top: 27px;

				@include r($xs-breakpoint) {
					margin-top: 23px;
				}
			}

		}
	}
}
