.containerFluid,
.container {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;
	position: relative;

	@include r($sm-breakpoint) {
		padding: 0 34px;
	}

	@include r($xs-breakpoint) {
		padding: 0 16px;
	}
}

.container {
	max-width: 1060px + 30px;

	@include r($sm-breakpoint) {
		max-width: 700px + 34px + 34px;
	}

	@include r($xs-breakpoint) {
		max-width: 100%;
	}

	&.container--xs {
		max-width: 700px;

		@include r($sm-breakpoint) {
			max-width: 700px + 34px + 34px;
		}
	}
}

.hiddenMobile {
	@include r($mob) {
		display: none;
	}
}

.hiddenDesktop {

	@include rmin($mob) {
		display: none;
	}
}

