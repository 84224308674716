.section--goodCard {
	padding: 18px 0 0;

	@include r($sm-breakpoint) {
		padding: 0 0 30px;
	}

	@include r($xs-breakpoint) {
		padding-bottom: 20px;
	}
}

.goodCard {
	display: flex;
	flex-wrap: wrap;
	position: relative;

	.goodCard__left {
		width: percentage(520/1060);

		@include r($sm-breakpoint) {
			width: 100%;
			max-width: 520px;
			margin: 0 auto;
		}
	}

	.goodCard__right {
		width: percentage(340/1060);
		margin-left: auto;
		text-align: left;
		color: #fff;
		padding: 60px 0;

		@include r($sm-breakpoint) {
			width: 520px;
			margin: 0 auto;
			padding: 20px 0;
		}

		@include r($xs-breakpoint) {
			padding: 40px 0 0;
			width: 100%;
		}


		> div {

			@include r($sm-breakpoint) {
				width: percentage(430/520);
			}

			@include r($xs-breakpoint) {
				width: 100%;
			}
		}

		.section__title {
			text-align: left;

			@include r($xs-breakpoint) {
				text-align: center;
			}
		}
	}
}


.goodCard__right {

	.goodCard__category {
		color: #949494;
		font-weight: 300;
		margin-top: 5px;

		@include r($sm-breakpoint) {
			margin-top: 0;
		}

		@include r($xs-breakpoint) {
			text-align: center;
			margin-top: -2px;
		}
	}

	.goodCard__text {
		margin: 23px 0 52px;
		font-weight: 300;

		@include r($sm-breakpoint) {
			margin: 24px 0 42px;
		}

		@include r($xs-breakpoint) {
			margin: 14px 0;
		}
	}

}

.goodCard__order {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 30px 0;
	padding: 0 15px 0 0 ;

	@include r($sm-breakpoint) {
		margin: 10px 0;
		justify-content: flex-start;
	}

	@include r($xs-breakpoint) {
	    justify-content: space-between;
		margin: 17px 0 0;
		padding: 0;
		flex-wrap: wrap;
	}

	> div {

		@include r($xs-breakpoint) {
			margin: 10px 0;
		}
	}

	.goodCard__button {

		@include r($sm-breakpoint) {
			margin-left: 40px;
		}

		@include r($xs-breakpoint) {
			margin-left: auto;
		}
	}
}

.goodCard__price {
	font-size: 26px;
	line-height: 26px;
	white-space: nowrap;

	@include r($xs-breakpoint) {
		font-size: 22px;
		line-height: 30px;
	}
}

.goodSlider--thumbs {
	background: #1E1E1E;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	position: relative;
	padding: 0 43px;
	margin-top: -4px;

	@include r($sm-breakpoint) {
		position: absolute;
		top: 0;
		right: 0;
	}

	@include r($xs-breakpoint) {
		position: relative;
		border-radius: 8px;
		padding: 0 15px;
		overflow: visible;
	}

	.swiper-container {
		padding: 46px 0 26px;

		@include r($xs-breakpoint) {
			padding: 31px 0 10px;
		}
	}

	.goodSlider__nameColor {
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		opacity: 0;
		visibility: hidden;
		transition: .3s all;
		margin-top: -32px;

		@include r($xs-breakpoint) {
			margin-top: -27px;
			font-size: 12px;
			line-height: 26px;
		}
	}

	.swiper-wrapper {
		position: static;
	}

	.swiper-slide {
		width: 50px;
		height: 50px;
		border: 1px solid transparent;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: .3s  border;
		position: static;

		&.is-active {
			border-color: #fff;


			.goodSlider__nameColor {
				opacity: 1;
				visibility: visible;
			}
		}

		img {
			width: 40px;
			height: 40px;
			object-fit: cover;
		}
	}
}

.goodSlider--main img {
	width: 100%;
}
