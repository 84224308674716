.section--about {
	padding: 17px 0 92px;

	@include r($sm-breakpoint) {
		padding: 19px 0 75px;
	}

	@include r($xs-breakpoint) {
		padding: 2px 0 12px;
	}

	.aboutPhoto {
		margin-bottom: 69px;

		@include r($sm-breakpoint) {
			margin-bottom: 58px;
		}

		@include r($xs-breakpoint) {
			margin-bottom: 39px;
		}
	}

	.section__title {
		color: #fff;
	}

	.textBlock {
		color: #fff;
		max-width: 700px;
		margin: 40px auto 0;
		font-weight: 300;

		@include r($sm-breakpoint) {
		    max-width: 520px;
		}

		@include r($xs-breakpoint) {
			margin-top: 23px;
			padding: 0 2px;
		}

	}
}
