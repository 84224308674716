.lang {
	display: flex;
	align-items: center;
	margin: 0 -14px;
	font-size: 14px;
	line-height: 17px;

	@include r($sm-breakpoint) {
		font-size: 12px;
		margin: 0 -12px;
	}

	.lang__item {
		margin: 0 14px;

		@include r($sm-breakpoint) {
			margin: 0 12px;
		}

		&.is-active a{
			color: #fff;
			pointer-events: none;
		}

		a {
			color: rgba(#fff, .5);
		}
	}
}
