.advantagesList {
	display: flex;
	flex-flow: wrap;
	margin: 0 -20px;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;

	@include r($xs-breakpoint) {
		margin: 0;
	}

	.advantagesList__item {
		width: calc(50% - 40px);
		position: relative;
		padding-left: 40px;
		margin: 0 20px;

		@include r($xs-breakpoint) {
			width: 100%;
			margin: 0;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 25px;
			height: 25px;
			background-image: url('../img/plus.svg');
			@include cover();
			margin-top: 5px;
		}

		&:not(:nth-child(-n+2)) {
			margin-top: 45px;

			@include r($xs-breakpoint) {
				margin-top: 0;
			}
		}

		&:not(:first-child) {

			@include r($xs-breakpoint) {
				margin-top: 36px;
			}
		}
	}
}
