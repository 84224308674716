.footer {
	font-size: 12px;
	line-height: 24px;

	.footer__top {
		padding: 78px 0 60px;

		@include r($sm-breakpoint) {
			padding: 43px 0 55px;
		}

		@include r($xs-breakpoint) {
			padding: 23px 0 67px;
		}

		.nav .menu {
			display: flex;
			flex-flow: wrap;
			margin: 0 -28px;

			@include r($sm-breakpoint) {
			    justify-content: center;
			}

			@include r($xs-breakpoint) {
				margin: 0;
				text-align: center;
				justify-content: flex-start;
			}

			.menu__item {
				margin: 0 28px;

				@include r($xs-breakpoint) {
					width: 50%;
					margin: 0;
				}

				&:not(:nth-child(-n+2)) {

					@include r($xs-breakpoint) {
						margin-top: 24px;
					}
				}
			}

			a {
				font-size: 16px;
				line-height: 24px;
				color: rgba(255, 255, 255, 0.5);
				font-weight: 300;

				&:hover {
					color: #fff;
				}
			}
		}

		+ .footer__bottom {
			padding: 0 0 40px;
		}
	}

	.footer__bottom {
		display: flex;
		align-items: center;
		padding: 78px 0 20px;
		margin: 0 -19px;

		@include r($sm-breakpoint) {
			display: block;
			text-align: center;
			padding: 18px 0;
		}

		@include r($xs-breakpoint) {
			padding: 32px 0;
		}

		> div {
			margin: 0 19px;


			&:not(:first-child) {

				@include r($sm-breakpoint) {
					margin-top: 5px;
				}
			}
		}
	}

	a {
		color: rgba(#fff, .5);

		&:hover {
			color: #fff;
		}
	}
}
