//@include font-face('Black', 900);

@font-face {
	font-family: 'MuseoSansCyrl';
	src: url('../fonts/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
	url('../fonts/MuseoSansCyrl-900.otf')  format('opentype'),
	url('../fonts/MuseoSansCyrl-900.woff') format('woff'),
	url('../fonts/MuseoSansCyrl-900.ttf')  format('truetype'),
	url('../fonts/MuseoSansCyrl-900.svg#MuseoSansCyrl-900') format('svg');
	font-weight: 900;
	font-style: normal;
}


@font-face {
	font-family: 'MuseoSansCyrl';
	src: url('../fonts/MuseoSansCyrl-700.eot');
	src: url('../fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
	url('../fonts/MuseoSansCyrl-700.woff') format('woff'),
	url('../fonts/MuseoSansCyrl-700.ttf') format('truetype'),
	url('../fonts/MuseoSansCyrl-700.svg#MuseoSansCyrl-700') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'MuseoSansCyrl';
	src: url('../fonts/MuseoSansCyrl-500.eot');
	src: url('../fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
	url('../fonts/MuseoSansCyrl-500.woff') format('woff'),
	url('../fonts/MuseoSansCyrl-500.ttf') format('truetype'),
	url('../fonts/MuseoSansCyrl-500.svg#MuseoSansCyrl-500') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'MuseoSansCyrl';
	src: url('../fonts/MuseoSansCyrl-300.eot');
	src: url('../fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
	url('../fonts/MuseoSansCyrl-300.woff') format('woff'),
	url('../fonts/MuseoSansCyrl-300.ttf') format('truetype'),
	url('../fonts/MuseoSansCyrl-300.svg#MuseoSansCyrl-300') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'MuseoSansCyrl';
	src: url('../fonts/MuseoSansCyrl-100.eot?#iefix') format('embedded-opentype'),
	url('../fonts/MuseoSansCyrl-100.otf')  format('opentype'),
	url('../fonts/MuseoSansCyrl-100.woff') format('woff'),
	url('../fonts/MuseoSansCyrl-100.ttf')  format('truetype'),
	url('../fonts/MuseoSansCyrl-100.svg#MuseoSansCyrl-100') format('svg');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas';
	src: url('../fonts/BebasBold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/BebasBold.woff') format('woff'),
	url('../fonts/BebasBold.ttf')  format('truetype');
	font-weight: normal;
	font-style: normal;
}

.section__title {
	font-size: 48px;
	line-height: 48px;
	text-align: center;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	font-family: $font-second;

	@include r($xs-breakpoint) {
		font-size: 34px;
		line-height: 34px;
	}
}

.textBlock {

	p {
		margin: 20px 0;
	}
}
