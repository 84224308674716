html {
	font-family: $font-main;
	-webkit-tap-highlight-color: transparent;
	@include font-smoothing();
	color: $text-color;
	font-size: 16px;
	line-height: 26px;
}

.pageWrapper,
html, body {
	background-color: #2A2A2A;
}

* {
	outline: none;
	box-sizing: border-box;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

a {
	transition: .25s all;
	text-decoration: none;
	color: $black;

	&:hover {
		color: $primary-color;
	}

	&:active {
		opacity: .8;
	}
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}

input:focus {
	outline: none;
}

::selection {
	color: $white;
	background-color: $primary-color;
}

::-moz-selection {
	color: $white;
	background-color: $primary-color;
}



:root {
	--browser-address-bar: 0px;
	--browser-bar: 0px;
}

.out {
	position: relative;
	overflow: hidden;
}

.pageWrapper {
	position: relative;
}

.no-scroll {
	overflow: hidden;
}

.is-fixed {
	position: fixed;
	top: 0;
	left: 0;
}

.lazy-load-wrap {
	img {
		width: 100%;
	}
}

.swiper-button:not(.swiper-button-disabled) svg circle {
	transition: .3s all;
}

.swiper-button:not(.swiper-button-disabled):hover {

	svg circle {
		fill: $primary-color;
	}
}
