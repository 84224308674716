.section--callback {

	@include r($sm-breakpoint) {
		padding: 30px 0 40px;
	}

	@include r($xs-breakpoint) {
		padding: 2px 0 20px;
	}
}

.callback {
	background-color: #fff;
	border-radius: 8px;
	color: #000;

	.callback__inner {
		max-width: 700px;
		margin: 0 auto;
		padding: 61px 0 90px;

		@include r($sm-breakpoint) {
		    max-width: 520px;
			padding: 61px 0 99px;
		}

		@include r($xs-breakpoint) {
			padding: 35px 20px 50px;
		}
	}

	.callback__address {
		margin: 50px 0 52px;

		@include r($xs-breakpoint) {
			margin: 25px 0 21px;
			padding: 0 20px;
		}
	}
}

.callback__address {
	font-weight: 300;
	text-align: center;
}

.form--callback {
	display: flex;
	flex-flow: wrap;
	margin: 0 -10px;

	> div {
		padding: 0 10px;
	}

	.form__field {
		width: 50%;
		margin-bottom: 30px;

		@include r($sm-breakpoint) {
			width: 100%;
		}

		@include r($xs-breakpoint) {
			margin-bottom: 25px;
		}

		&.form__field--textarea {
			width: 100%;
		}
	}

	.form__button {
		width: 100%;
		text-align: center;
		margin-top: 12px;

		@include r($xs-breakpoint) {
			margin-top: 8px;
		}
	}

	.form__textarea {
	}
}
