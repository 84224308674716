.fancybox-slide {
	padding: 0;
	text-align: left;
}

.menuBox {
	height: 100%;
	width: percentage(274/320);
	display: none;
	margin-left: 0;
	padding: 0;


	.menuBox__inner {
		background: #FFFFFF;
		box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.06);
		height: 100%;
		display: flex;
		flex-flow: column;
	}

	.menuBox__head {
		padding: 17px 16px;
		position: relative;


		.menuBox__logo {
			@include vcenter();
			margin-left: 24px;
			margin-top: -6px;
		}
	}

	.menuBox__body {
		margin: 37px 0;
	}

	.menuBox__bottom {
		margin-top: auto;
		padding: 0 16px 25px;
	}

	.menu {
		padding: 16px;

		.menu__item:not(:first-child) {
			margin-top: 30px;
		}

		.menu__item.is-active a {
			color: #000;
			pointer-events: none;
		}

		a {
			font-size: 22px;
			line-height: 30px;
			/* or 136% */
			color: rgba(0, 0, 0, 0.5);
		}
	}

	.lang {
		font-size: 16px;
		line-height: 19px;

		.lang__item.is-active a {
			color: #000;
		}

		a {
			color: rgba(#000000, .5);
		}

	}
}
