.dropdown {
	padding-top: 25px;
	opacity: 0;
	visibility: hidden;

	@include r($sm-breakpoint) {
		padding: 0;
		width: 100%;
		height: 100%;
		opacity: 1;
		visibility: visible;
		display: none;
	}

	.fancybox-close-small {
		display: none;
	}

	&__head {
		width: 100%;
		display: none;
		margin-bottom: 90px;

		@include r($sm-breakpoint) {
			display: block;
			margin-bottom: 0;
		}

		.dropdown__close {
			position: absolute;
			top: 33px;
			right: 33px;

			@include r($xs-breakpoint) {
				top: 17px;
				right: 16px;
			}

			svg {

				@include r($xs-breakpoint) {
					width: 36px;
					height: 36px;
				}
			}
		}
	}

	&__title {
		font-size: 26px;
		line-height: 26px;
		text-align: center;
		color: #FFFFFF;
		text-align: center;

		@include r($xs-breakpoint) {
			font-size: 22px;
			line-height: 30px;
		}
	}

	&__inner {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 52px 90px 68px;
		position: relative;
		width: 886px;
		background: #454545;
		box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.3);
		border-radius: 8px;

		@include r($sm-breakpoint) {
			width: 100%;
			height: 100%;
			border-radius: 0;
			padding: 40px 0;
			justify-content: flex-start;
			flex-flow: column;
			align-items: center;
		}

		@include r($xs-breakpoint) {
			padding: 20px 0;
		}

		.dropdown__left {
			width: percentage(340/700);
			padding-left: 6px;
			padding-top: 18px;

			@include r($sm-breakpoint) {
				width: 100%;
				max-width: 520px;
				padding-left: 0;
				padding-top: 0;
			}

			@include r($xs-breakpoint) {
				display: none;
			}
		}

		.dropdown__right {
			width: percentage(340/700);

			@include r($sm-breakpoint) {
				width: 100%;
				max-width: 520px;
				margin-top: 122px;
				text-align: center;
			}

			@include r($xs-breakpoint) {
				margin-top: 0;
				padding-top: 60px;
				height: calc(100% - 30px);
			}

			.dropdownSlider {

				@include r($xs-breakpoint) {
					height: 100%;
				}

				.swiper-container {

					@include r($xs-breakpoint) {
						height: 100%;
					}

					.swiper-pagination-bullet {
						margin: 0 6px;
						width: 6px;
						height: 6px;
						background-color: #fff;
					}
				}
			}
		}
	}


	.dropdownMenu {
		display: flex;
		flex-wrap: wrap;
		font-size: 16px;
		line-height: 24px;

		.dropdownMenu__item {
			width: 100%;

			@include r($sm-breakpoint) {
				//width: 33.33%;
				text-align: center;
			}

			&.is-active a {
				color: #fff;
			}

			&:not(:first-child) {
				margin-top: 30px;
			}

			//&:not(:nth-child(-n+2)) {
			//	margin-top: 28px;

				//@include r($sm-breakpoint) {
				//	margin-top: 0;
				//}
			//}

			//&:not(:nth-child(-n+3)) {

				//@include r($sm-breakpoint) {
				//	margin-top: 29px;
				//}
			//}

			a {
				font-weight: 300;

				@include r($sm-breakpoint) {
					color: rgba(#fff, .5);
				}
			}
		}
	}
}

.dropdownSlider {

	@include r($xs-breakpoint) {
		padding: 0 15px;
	}

	.swiper-slide {
		opacity: 0 !important;
		transition: none;

		&.swiper-slide-active {
			opacity: 1 !important;
			transition: .3s all;

			img {
				transform: translateX(0);
				transition: .4s all ease-in-out;
			}
		}

		img {
			transform: translateX(40px);

			+ span {
				display: none;
				font-weight: 300;
				font-size: 16px;
				line-height: 24px;
				color: #fff;
				margin-top: 18px;

				@include r($xs-breakpoint) {
					display: block;
				}
			}
		}
	}

	.swiper-pagination {
		display: none;

		@include r($xs-breakpoint) {
			display: block;
		}
	}

}
